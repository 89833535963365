<template>
  <div>
    <h3 class="text-secondary mb-5">
      {{ $t("page.event.new-event") }}
    </h3>
    <validation-observer ref="observer" v-slot="{ invalid }">
      <b-form>
        <validation-provider
          name="title"
          :rules="{ required: true }"
          v-slot="validationContext"
        >
          <b-form-group :label="$t('page.event.title') + '*'">
            <b-form-input
              :state="getValidationState(validationContext)"
              v-model="title"
            />
          </b-form-group>
        </validation-provider>
        <validation-provider
          name="sede"
          :rules="{ required: true }"
          v-slot="validationContext"
        >
          <b-form-group :label="$t('page.event.select-condominium') + '*'">
            <b-form-select
              :state="getValidationState(validationContext)"
              v-model="condominium"
              :options="condominiums"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          name="group"
          :rules="{ required: true }"
          v-slot="validationContext"
        >
          <b-form-group :label="$t('page.event.select-group') + '*'">
            <b-form-select
              :state="getValidationState(validationContext)"
              v-model="group"
              :options="groups"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <b-row no-gutters>
          <b-row no-gutters>
            <validation-provider
              name="date"
              :rules="{ required: true, date: 'DD/MM/YYYY' }"
              v-slot="validationContext"
            >
              <b-form-group :label="$t('date') + '*'">
                <b-input-group class="mb-3">
                  <b-form-input
                    name="date"
                    :state="getValidationState(validationContext)"
                    v-model="formattedDate"
                    type="text"
                    readonly
                    placeholder="DD/MM/YYYY"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="date"
                      button-only
                      right
                      locale="it"
                      :min="minDate"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: '2-digit'
                      }"
                      aria-controls="example-input"
                      @context="onContext"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-row>
          <validation-provider
            name="time"
            :rules="{ required: true, time: 'HH:mm' }"
            v-slot="validationContext"
          >
            <b-form-group :label="$t('time') + '*'" class="ml-2">
              <b-form-input
                :state="getValidationState(validationContext)"
                v-model="time"
                type="time"
                locale="it"
              ></b-form-input>
            </b-form-group>
          </validation-provider>
          <validation-provider
            name="location"
            rules="required"
            v-slot="validationContext"
            style="flex-grow: 1"
          >
            <b-form-group :label="$t('location') + '*'" class="ml-2">
              <b-form-input
                :state="getValidationState(validationContext)"
                v-model="location"
                required
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-row>
        <validation-provider
          name="body"
          :rules="{ required: true }"
          v-slot="validationContext"
        >
          <b-form-group
            style="flex-grow: 1"
            :label="$t('page.event.text') + '*'"
            class="ml-2"
          >
            <b-form-textarea
              :state="getValidationState(validationContext)"
              v-model="body"
            ></b-form-textarea>
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <b-row no-gutters class="mt-4">
          <label>{{ $t("page.event.images") }}</label>
        </b-row>
        <b-row no-gutters>
          <b-col>
            <file-uploader
              fileTypes="image/*"
              @addFiles="addPhotos"
              :multiple="true"
            ></file-uploader>
          </b-col>
          <b-col class="pl-4">
            <b-table small :items="photos" :fields="photoFields">
              <template #head()="data">
                <div>
                  {{ $t(data.label) }}
                </div>
              </template>
              <template #cell(photo)="data">
                {{ data.item.filename }}
              </template>
              <template #cell(remove)="data">
                <b-button
                  variant="danger"
                  size="sm"
                  @click="removePhoto(data.item, data.index)"
                >
                  <span>{{ $t("button.delete") }} </span>
                  <b-icon icon="backspace" />
                </b-button>
              </template>
            </b-table>
            <gallery :photos="photos" />
          </b-col>
        </b-row>
        <b-row no-gutters class="mt-4">
          <label>{{ $t("page.event.attachments") }}</label>
        </b-row>
        <b-row no-gutters>
          <b-col>
            <file-uploader
              @addFiles="addFiles"
              :multiple="true"
            ></file-uploader>
          </b-col>
          <b-col class="pl-4">
            <b-table small :items="files" :fields="fileFields">
              <template #head()="data">
                <div>
                  {{ $t(data.label) }}
                </div>
              </template>
              <template #cell(document)="data">
                <span>{{ data.item.filename }}</span>
              </template>
              <template #cell(remove)="data">
                <b-button
                  variant="danger"
                  size="sm"
                  @click="removeFile(data.item, data.index)"
                >
                  <span>{{ $t("button.delete") }} </span>
                  <b-icon icon="backspace" />
                </b-button>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-form>
      <b-row no-gutters style="justify-content: end">
        <b-button variant="secondary" @click="$router.go(-1)">{{
          $t("button.cancel")
        }}</b-button>
        <b-button
          class="ml-2 text-white"
          :disabled="invalid"
          @click="addEvent"
          >{{ $t("page.event.publish") }}</b-button
        >
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import FileUploader from "../components/Base/BaseFileDropUploader.vue";
import { BASE64_TO_FILE, FILE_TO_BASE64, RESIZE_IMG } from "../Utils";
import uniqueId from "lodash/uniqueId";
import dayjs from "dayjs";
import Gallery from "../components/Base/BaseGallery.vue";

const localFileIdPrefix = "local_";

export default {
  components: {
    FileUploader,
    Gallery
  },
  created() {
    this.$store.dispatch("condominium/retrieveCondominiums");
    this.$store.dispatch("groups/retrieveGroups");
  },
  data() {
    const minDate = dayjs().toDate();
    return {
      files: [],
      photos: [],
      body: "",
      formattedDate: "",
      date: "",
      time: "",
      location: "",
      title: "",
      consumerGroup: undefined,
      condominium: undefined,
      minDate: minDate,
      photoFields: [
        { key: "photo", label: "photos" },
        { key: "remove", label: "" }
      ],
      fileFields: [
        { key: "document", label: "documents" },
        { key: "remove", label: "" }
      ]
    };
  },
  computed: {
    groups() {
      return this.$store.getters["groups/getGroups"].map(g => ({
        value: g._id,
        text: g.name
      }));
    },
    group: {
      get() {
        return this.consumerGroup ? this.consumerGroup : null;
      },
      set(val) {
        this.consumerGroup = val;
      }
    },
    condominiums() {
      return this.$store.getters["condominium/getCondominiums"].map(c => ({
        value: c._id,
        text: c.name
      }));
    },
    hasPhotos() {
      return this.post.photos || this.post.photo;
    }
  },
  methods: {
    onContext(val) {
      this.formattedDate = val.activeFormatted;
    },
    async addEvent(ev) {
      this.$store.commit("loader/SET_LOADER", true);
      try {
        const payload = {
          "groups[]": [this.group],
          body: this.body,
          type: "event",
          title: this.title,
          time: this.time,
          date: this.formattedDate,
          location: this.location
        };
        if (this.files.length) {
          payload["documents[]"] = this.files.map(f => f.file);
        }

        if (this.photos.length) {
          const photoFiles = [];
          for (const photo of this.photos) {
            const file = await BASE64_TO_FILE(photo.src, "event");
            photoFiles.push(file);
          }
          this.photos.forEach(item => {});
          payload["photos[]"] = photoFiles;
        }
        await this.$store.dispatch("event/createEvent", {
          condominiumId: this.condominium,
          payload
        });
        this.$router.push({
          name: "Events"
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    constructLocalFileData(file) {
      return {
        _id: uniqueId(localFileIdPrefix),
        filename: file.name || file.photo.name || "",
        src: file.src,
        file
      };
    },
    getValidationState({ valid, touched }) {
      if (touched) return valid ? null : valid;
      else return null;
    },
    removePhoto(photo, index) {
      console.log(photo);
      if (index > -1) {
        this.photos.splice(index, 1);
      }
    },
    addFiles(files) {
      files.forEach(f => this.files.push(this.constructLocalFileData(f)));
    },
    addPhotos(photos) {
      photos.forEach(async photo => {
        try {
          const base64 = await FILE_TO_BASE64(photo);
          const res = await RESIZE_IMG(base64, photo.type);
          this.photos.push(
            this.constructLocalFileData({
              photo,
              src: res
            })
          );
        } catch (error) {
          console.error(error);
        }
      });
    },
    removeFile(file) {
      const matchFileIndex = this.files.findIndex(f => f._id === file._id);
      if (matchFileIndex === -1) return;

      this.files.splice(matchFileIndex, 1);
    }
  }
};
</script>

<style></style>
